import {Component, Input, OnInit, forwardRef} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {NushDecisionDomain} from '@nit-core/models/nush-decision';
import {NitFieldWrapperComponent} from '../../../../../../../../core/forms/components/field-wrapper/field-wrapper.component';
import {DropDownListComponent} from '@progress/kendo-angular-dropdowns';

@UntilDestroy()
@Component({
  selector: 'nit-education-sphere-dropdown',
  templateUrl: './education-sphere-dropdown.component.html',
  styleUrl: './education-sphere-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EducationSphereDropdownComponent)
    }
  ],
  standalone: true,
  imports: [NitFieldWrapperComponent, DropDownListComponent, FormsModule]
})
export class EducationSphereDropdownComponent implements OnInit {
  @Input() domains: NushDecisionDomain[];

  disabled: boolean = false;
  touched: boolean = false;
  value: NushDecisionDomain;
  defaultItem: {name: string, id: string};

  onChange = (val: any) => {
    this.value = val;
  };
  onTouched = () => { };

  ngOnInit(): void {
    this.defaultItem = {
      name: 'Обрати галузь',
      id: null
    };
  }

  writeValue(val: NushDecisionDomain): void {
    this.value = val;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}

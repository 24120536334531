import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, forwardRef} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {NushDecision, NushDecisionRule} from '@nit-core/models/nush-decision';
import {SemesterSettings} from '@nit-core/models';
import {CommonModule} from '@angular/common';
import {NitFieldWrapperComponent} from '@nit-forms';
import {DropDownListComponent, ItemTemplateDirective, ValueTemplateDirective, NoDataTemplateDirective} from '@progress/kendo-angular-dropdowns';

@UntilDestroy()
@Component({
  selector: 'nit-student-dropdown',
  templateUrl: './student-dropdown.component.html',
  styleUrl: './student-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StudentDropdownComponent)
    }
  ],
  standalone: true,
  imports: [CommonModule, NitFieldWrapperComponent, DropDownListComponent, FormsModule, ItemTemplateDirective, ValueTemplateDirective, NoDataTemplateDirective]
})
export class StudentDropdownComponent implements OnChanges {
  @Input() classId?: string;
  @Input() scheduleId: string;
  @Input() childId: string;
  @Input() students: NushDecision[] = [];
  @Output() observationCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  disabled: boolean = false;
  touched: boolean = false;
  value: any;
  isFirstSelected: boolean = false;
  isLastSelected: boolean = false;
  isClassObservationAllowed: boolean;

  onChange: (val: NushDecision) => void = () => { };
  onTouched = () => { };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.students) {
      this.handleSelectedItems();
    }
  }

  handleSelectedItems(): void {
    const selectedIndex: number = this.students.findIndex(x => x.id === this.value?.id);
    this.isFirstSelected = selectedIndex === 0;
    this.isLastSelected = selectedIndex === this.students.length - 1;
  }

  studentChanged(val: NushDecision): void {
    this.onChange(val);
    this.handleSelectedItems();
  }

  changeStudent(event: 'next' | 'prev'): void {
    const selectedIndex: number = this.students.findIndex(x => x.id === this.value?.id);
    if (event === 'next' && selectedIndex !== this.students.length - 1) {
      this.value = this.students[selectedIndex + 1];
    }
    if (event === 'prev' && selectedIndex !== 0) {
      this.value = this.students[selectedIndex - 1];
    }
    this.onChange(this.value);
    this.handleSelectedItems();
  }

  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private _getSemesterSettings(rule: NushDecisionRule, scheduleId: string): SemesterSettings {
    return rule.firstSemesterSettings.scheduleId === scheduleId ?
      rule.firstSemesterSettings : rule.secondSemesterSettings;
  }
}

<nit-field-wrapper *ngIf="classId" label="Учень" class="student-filter">
  <kendo-dropdownlist class="dropdown-filter"
                      valueField="id"
                      textField="number"
                      [popupSettings]="{popupClass: 'student-dropdown-list'}"
                      [data]="students"
                      [valuePrimitive]="false"
                      [(ngModel)]="value"
                      (valueChange)="studentChanged($event)">
    <ng-template let-dataItem kendoDropDownListItemTemplate>
      <span class="template">{{dataItem?.fullName}}</span>
      <span *ngIf="dataItem?.leaveTransferId" class="excluded">(Вибув)</span>
    </ng-template>

    <ng-template let-dataItem kendoDropDownListValueTemplate>
      <span class="template">{{dataItem?.fullName}}</span>
      <span *ngIf="dataItem?.leaveTransferId" class="excluded">(Вибув)</span>
    </ng-template>

    <ng-template kendoDropDownListNoDataTemplate>
      <div>
        Немає доступних учнів
      </div>
    </ng-template>
  </kendo-dropdownlist>
</nit-field-wrapper>
